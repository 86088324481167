import { render, staticRenderFns } from "./ShareOfSearch.vue?vue&type=template&id=4e6c518e&scoped=true&lang=pug"
import script from "./ShareOfSearch.vue?vue&type=script&lang=js"
export * from "./ShareOfSearch.vue?vue&type=script&lang=js"
import style0 from "./ShareOfSearch.vue?vue&type=style&index=0&id=4e6c518e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e6c518e",
  null
  
)

export default component.exports